import Casecard from "./components/Casecard";

function App() {
  return (
    <div>
      <div className="flex flex-col max-w-3xl items-start justify-between gap-4">
        <img src="/vic.png" className="min-w-20 min-h-20" alt="Victor Afia" />
        <h1>Technical Product Manager & Software Developer</h1>
        <p className="text-gray-800 font-normal text-base text-left">
          Hey! I'm &lt;Afia Victor /&gt;. Bridging the gap between product
          vision and technical execution drive value and empowering efficient
          work is my favorite thing. I'm currently building{" "}
          <a
            href="https://delveai.brimble.app "
            className=" inline text-[#3C66FF] "
          >
            Delve AI
          </a>
          , a webapp that simplifies document interaction with instant memories,
          voice -read and Q&A features to boost productivity.
        </p>
        <p className="text-gray-800 font-normal text-base text-left">
          Previously at{" "}
          <a href="https://trendit3.com" className=" inline text-[#3C66FF] ">
            Trendit3
          </a>
        </p>
      </div>
      <div className="flex flex-col gap-14 items-center max-w-2xl ">
        <Casecard
          title="Delve AI"
          imgurl="/delve.png"
          subtxt="AI - powered platform simplifying documents"
          year={2024}
        />
        <Casecard
          title="Pamvaj"
          imgurl="/pamvaj.png"
          subtxt="Connecting users with local services"
          year={2024}
        />
        <Casecard
          title="Frelapay"
          imgurl="/frelapay.png"
          subtxt="Payment solution for Freelancers"
          year={2024}
        />
      </div>
    </div>
  );
}

export default App;
